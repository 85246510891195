const ROUTES = {
  "/": {
    "key": "home-page",
    "name": "HomePageCd34a0f494a3445fA2c5687132475506",
    "type": "HTML"
  },
  "/_admin/*": {
    "key": "admin-pages",
    "name": "AdminPages076a9452Be37425dAfc52f4755b5f4a7",
    "type": "HTML"
  },
  "/about": {
    "key": "about-us-page",
    "name": "AboutUsPageB78a838aEde64259Ade3185e82fc2f71",
    "type": "HTML"
  },
  "/about/leveling": {
    "key": "about-leveling-page",
    "name": "AboutLevelingPage9448ddf9E7dd4b52Ab5a639dc6345190",
    "type": "HTML"
  },
  "/events/public": {
    "key": "public-events-page",
    "name": "PublicEventsPageFa8715cc22f744a489928e0f4979845a",
    "type": "HTML"
  },
  "/join": {
    "key": "join-us-page",
    "name": "JoinUsPageB875134181ac4417A11eA784bc8b523a",
    "type": "REFERENCE"
  },
  "/members/*": {
    "key": "member-pages",
    "name": "MemberPages15378abaC30e4e908489Af103ed1f8e3",
    "type": "HTML"
  },
  "/members/messages/*": {
    "key": "message-pages",
    "name": "MessagePagesDc9d4c54954a443bB3ccC65117d4866c",
    "type": "HTML"
  },
  "/resources": {
    "key": "resources-page",
    "name": "ResourcesPageEe4388b908e3489996b34725fe3f71a5",
    "type": "REFERENCE"
  },
  "/signup/*": {
    "key": "signup-pages",
    "name": "SignupPages30c49ee9754343ef8709D0f8d8fff46f",
    "type": "REFERENCE"
  }
};

export default ROUTES;