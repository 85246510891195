import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "d9b90aa3-2034-415d-95c7-99c10eb4a675"
// title: "Title - Leveling"
// type: :text
// key: "title"
// parent_id: nil
export const TitleD9b90aa32034415d95c799c10eb4a675 = "Leveling";

// id: "ee4388b9-08e3-4899-96b3-4725fe3f71a5"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPageEe4388b908e3489996b34725fe3f71a5(props: any) {
  return (
    <SecondaryPage7e0dc75fF32b45d88bd3D408985fdfda parentTag="ResourcesPageEe4388b908e3489996b34725fe3f71a5" header={<SecondaryMarketingHeader523d07029d3645c7872d99557df1e89d parentTag="Header7340cd2e85f24c7f8081723f06420f48" title="Resources" />} {...props} />
  );
}

// id: "30c49ee9-7543-43ef-8709-d0f8d8fff46f"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPages30c49ee9754343ef8709D0f8d8fff46f(props: any) {
  return (
    <SecondaryPage7e0dc75fF32b45d88bd3D408985fdfda parentTag="SignupPages30c49ee9754343ef8709D0f8d8fff46f" header={<SecondaryMarketingHeader523d07029d3645c7872d99557df1e89d parentTag="Header7a712765D1054d118faaC7725d66ed6a" title="Join Us" />} {...props} />
  );
}

// id: "9448ddf9-e7dd-4b52-ab5a-639dc6345190"
// title: "Levels 1-4 Explained"
// type: :html
// key: "about-leveling-page"
// parent_id: nil
export function AboutLevelingPage9448ddf9E7dd4b52Ab5a639dc6345190(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutLevelingPage9448ddf9E7dd4b52Ab5a639dc6345190`}>
      {props["header"] || <SecondaryMarketingHeader523d07029d3645c7872d99557df1e89d parentTag="HeaderB2de0b61Acd54c75BcafD99697967826" title="Leveling" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter58f090de562840278f50E0c9eca3f31c parentTag="Footer7c3354f2A5fa4cc786a1807f3424cbab" {...props} />}
    </div>
  );
}

// id: "23629f14-0e36-4851-bae3-46180a44cf41"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "15378aba-c30e-4e90-8489-af103ed1f8e3"
export function Footer23629f140e364851Bae346180a44cf41(props: any) {
  return (
    <DefaultFooter58f090de562840278f50E0c9eca3f31c parentTag="Footer23629f140e364851Bae346180a44cf41" {...props} />
  );
}

// id: "4f46388f-f5ae-45b6-a6d6-dbc626ee52aa"
// title: ""
// type: :reference
// key: "header"
// parent_id: "7e0dc75f-f32b-45d8-8bd3-d408985fdfda"
export function Header4f46388fF5ae45b6A6d6Dbc626ee52aa(props: any) {
  return (
    <SecondaryMarketingHeader523d07029d3645c7872d99557df1e89d parentTag="Header4f46388fF5ae45b6A6d6Dbc626ee52aa" title="Secondary Page" {...props} />
  );
}

// id: "cbc977a8-1900-41ef-beb3-955f39e56908"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "e996e7ab-3f2c-4cb4-b62c-b66db50a7e91"
export const ButtonClassCbc977a8190041efBeb3955f39e56908 = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "cc6c3520-12d8-43eb-8c6c-8f08e3011bac"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "3a55e1a6-89b6-4ba6-aa8a-8162bc14c090"
export function ButtonCc6c352012d843eb8c6c8f08e3011bac(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("ButtonCc6c352012d843eb8c6c8f08e3011bac", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "f720cb35-f795-4ceb-ac94-af9f9bcb0287"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "fa8715cc-22f7-44a4-8992-8e0f4979845a"
export function FooterF720cb35F7954cebAc94Af9f9bcb0287(props: any) {
  return (
    <DefaultFooter58f090de562840278f50E0c9eca3f31c parentTag="FooterF720cb35F7954cebAc94Af9f9bcb0287" {...props} />
  );
}

// id: "8e9bb266-12fc-4e59-b775-858541d1d87c"
// title: ""
// type: :text
// key: "alt"
// parent_id: "a182164a-a29b-4481-96f6-c7cc43bdcac1"
export const Alt8e9bb26612fc4e59B775858541d1d87c = "logo";

// id: "4591d73c-f9e3-47a7-8fe1-147f670c7d1c"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "afb2222d-3f22-4474-8715-252a9b885249"
export const NavMenuSlug4591d73cF9e347a78fe1147f670c7d1c = "members-primary-nav";

// id: "77d485bd-92a3-4303-b7a1-d87e9de87d94"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "02e840e9-1647-4847-b28f-835a11bf06fa"
export const NavMenuSlug77d485bd92a34303B7a1D87e9de87d94 = "footer-nav";

// id: "5403b5f7-e27d-4bf2-b97b-529d98d54adc"
// title: ""
// type: :text
// key: "title"
// parent_id: "b2de0b61-acd5-4c75-bcaf-d99697967826"
export const Title5403b5f7E27d4bf2B97b529d98d54adc = "Leveling";

// id: "076a9452-be37-425d-afc5-2f4755b5f4a7"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPages076a9452Be37425dAfc52f4755b5f4a7(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPages076a9452Be37425dAfc52f4755b5f4a7`}>
      {props.children}
    </div>
  );
}

// id: "0bd3bb01-82e5-4eef-9e28-284b7cbb643b"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label0bd3bb0182e54eef9e28284b7cbb643b(props: any) {
  return (
    <DefaultLogoA182164aA29b448196f6C7cc43bdcac1 parentTag="Label0bd3bb0182e54eef9e28284b7cbb643b" {...props} />
  );
}

// id: "00ddc5c5-c833-4139-b48a-6e4c0f3395c5"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero00ddc5c5C8334139B48a6e4c0f3395c5(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero00ddc5c5C8334139B48a6e4c0f3395c5 hero`}>
      <div className="title">
        {props["title"] || TitleE89283697b0a4183B40b5fb216437032}
      </div>
    </div>
  );
}

// id: "dc9d4c54-954a-443b-b3cc-c65117d4866c"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePagesDc9d4c54954a443bB3ccC65117d4866c(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePagesDc9d4c54954a443bB3ccC65117d4866c`}>
      {props["header"] || <MembersAreaHeader36ac42e4C69341c3Bbff06083b4501ae parentTag="Header3ecbfa8928854d879070A319d5086aab" {...props} />}
      {props.children}
    </div>
  );
}

// id: "a85d0570-53d5-407d-a62d-047d3254e040"
// title: ""
// type: :reference
// key: "header"
// parent_id: "4c395058-ca9f-4355-b4db-ca5b8872ec4d"
export function HeaderA85d057053d5407dA62d047d3254e040(props: any) {
  return (
    <MembersAreaHeader36ac42e4C69341c3Bbff06083b4501ae parentTag="HeaderA85d057053d5407dA62d047d3254e040" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "9ecd1fe7-562b-4fdb-b6ad-8fb4a222d4da"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "e996e7ab-3f2c-4cb4-b62c-b66db50a7e91"
export const PostSlug9ecd1fe7562b4fdbB6ad8fb4a222d4da = "home-page-posts2";

// id: "73eba9de-a2f1-47e5-9455-4161daa4e3c0"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "15378aba-c30e-4e90-8489-af103ed1f8e3"
export function SecondaryNav73eba9deA2f147e594554161daa4e3c0(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNav73eba9deA2f147e594554161daa4e3c0", parentTag)} {...props} />
  );
}

// id: "e9d5b8d7-eea9-4ac8-a16a-a3aa34f549b2"
// title: ""
// type: :reference
// key: "header"
// parent_id: "fa8715cc-22f7-44a4-8992-8e0f4979845a"
export function HeaderE9d5b8d7Eea94ac8A16aA3aa34f549b2(props: any) {
  return (
    <SecondaryMarketingHeader523d07029d3645c7872d99557df1e89d parentTag="HeaderE9d5b8d7Eea94ac8A16aA3aa34f549b2" title="Public Events" {...props} />
  );
}

// id: "e8928369-7b0a-4183-b40b-5fb216437032"
// title: ""
// type: :text
// key: "title"
// parent_id: "00ddc5c5-c833-4139-b48a-6e4c0f3395c5"
export const TitleE89283697b0a4183B40b5fb216437032 = "Default Title";

// id: "7c3354f2-a5fa-4cc7-86a1-807f3424cbab"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "9448ddf9-e7dd-4b52-ab5a-639dc6345190"
export function Footer7c3354f2A5fa4cc786a1807f3424cbab(props: any) {
  return (
    <DefaultFooter58f090de562840278f50E0c9eca3f31c parentTag="Footer7c3354f2A5fa4cc786a1807f3424cbab" {...props} />
  );
}

// id: "7a77ec28-530b-427c-a614-5d49d5e99e90"
// title: ""
// type: :text
// key: "href"
// parent_id: "cc6c3520-12d8-43eb-8c6c-8f08e3011bac"
export const Href7a77ec28530b427cA6145d49d5e99e90 = "/join";

// id: "cd7adecd-eb28-471c-a42c-0141c002e833"
// title: ""
// type: :text
// key: "class"
// parent_id: "661f9fee-f3f2-4dd6-a6e2-80196a003c48"
export const ClassCd7adecdEb28471cA42c0141c002e833 = "linked-logo";

// id: "daac287e-8710-4911-9d0f-3eae43b0e6b2"
// title: ""
// type: :text
// key: "title"
// parent_id: "7340cd2e-85f2-4c7f-8081-723f06420f48"
export const TitleDaac287e871049119d0f3eae43b0e6b2 = "Resources";

// id: "eac829dd-9e6b-4a54-8bf3-5fd8e542eec5"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeaderEac829dd9e6b4a548bf35fd8e542eec5(props: any) {
  return (
    <ShortHeaderC55b58f9E0c24433Ab4aEfe5933ca7fc parentTag="DefaultHeaderEac829dd9e6b4a548bf35fd8e542eec5" navMenuSlug="marketing-primary-nav" title {...props} />
  );
}

// id: "15378aba-c30e-4e90-8489-af103ed1f8e3"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPages15378abaC30e4e908489Af103ed1f8e3(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPages15378abaC30e4e908489Af103ed1f8e3`}>
      {props["header"] || <MembersAreaHeader36ac42e4C69341c3Bbff06083b4501ae parentTag="Header631e1384C4f441b6A1cdD9a4a6f40d35" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNav73eba9deA2f147e594554161daa4e3c0", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter58f090de562840278f50E0c9eca3f31c parentTag="Footer23629f140e364851Bae346180a44cf41" {...props} />}
    </div>
  );
}

// id: "8a74f990-210b-4ada-bd21-b2f3958cd134"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function Header8a74f990210b4adaBd21B2f3958cd134(props: any) {
  return (
    <MembersAreaHeader36ac42e4C69341c3Bbff06083b4501ae parentTag="Header8a74f990210b4adaBd21B2f3958cd134" {...props} />
  );
}

// id: "3a55e1a6-89b6-4ba6-aa8a-8162bc14c090"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHero3a55e1a689b64ba6Aa8a8162bc14c090(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHero3a55e1a689b64ba6Aa8a8162bc14c090 hero`}>
      <div className="title">
        Our mission is to change the world by promoting healthy intimacy through community and education.
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("ButtonCc6c352012d843eb8c6c8f08e3011bac", parentTag)} href="/join" label="Join Us" {...props} />}
      </div>
    </div>
  );
}

// id: "631e1384-c4f4-41b6-a1cd-d9a4a6f40d35"
// title: ""
// type: :reference
// key: "header"
// parent_id: "15378aba-c30e-4e90-8489-af103ed1f8e3"
export function Header631e1384C4f441b6A1cdD9a4a6f40d35(props: any) {
  return (
    <MembersAreaHeader36ac42e4C69341c3Bbff06083b4501ae parentTag="Header631e1384C4f441b6A1cdD9a4a6f40d35" {...props} />
  );
}

// id: "830430d6-4694-4f37-86f2-0bd0071f671a"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "4bcfbad5-312e-48a2-8dc2-d3a99ec63a70"
export function Hero830430d646944f3786f20bd0071f671a(props: any) {
  return (
    <HomePageHero3a55e1a689b64ba6Aa8a8162bc14c090 parentTag="Hero830430d646944f3786f20bd0071f671a" {...props} />
  );
}

// id: "65e244db-bc03-45d3-bca6-6370e6b7b6a8"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "58f090de-5628-4027-8f50-e0c9eca3f31c"
export function Copyright65e244dbBc0345d3Bca66370e6b7b6a8(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("Copyright65e244dbBc0345d3Bca66370e6b7b6a8", parentTag)} {...props} />
  );
}

// id: "b4e07b54-10ce-499b-ae4c-653b383080e2"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "7e0dc75f-f32b-45d8-8bd3-d408985fdfda"
export function FooterB4e07b5410ce499bAe4c653b383080e2(props: any) {
  return (
    <DefaultFooter58f090de562840278f50E0c9eca3f31c parentTag="FooterB4e07b5410ce499bAe4c653b383080e2" {...props} />
  );
}

// id: "7340cd2e-85f2-4c7f-8081-723f06420f48"
// title: ""
// type: :reference
// key: "header"
// parent_id: "ee4388b9-08e3-4899-96b3-4725fe3f71a5"
export function Header7340cd2e85f24c7f8081723f06420f48(props: any) {
  return (
    <SecondaryMarketingHeader523d07029d3645c7872d99557df1e89d parentTag="Header7340cd2e85f24c7f8081723f06420f48" title="Resources" {...props} />
  );
}

// id: "46ac364d-bde3-49cb-8453-f716d0eec34e"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "58f090de-5628-4027-8f50-e0c9eca3f31c"
export function Groupflow46ac364dBde349cb8453F716d0eec34e(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("Groupflow46ac364dBde349cb8453F716d0eec34e", parentTag)} {...props} />
  );
}

// id: "71d47674-c64f-41be-a6c0-ba16a11b7a12"
// title: ""
// type: :text
// key: "title"
// parent_id: "e9d5b8d7-eea9-4ac8-a16a-a3aa34f549b2"
export const Title71d47674C64f41beA6c0Ba16a11b7a12 = "Public Events";

// id: "90fc758b-f07b-43a0-b391-8ec730979dda"
// title: ""
// type: :text
// key: "title"
// parent_id: "80081c25-03f0-4664-bba5-fe7c59a05117"
export const Title90fc758bF07b43a0B3918ec730979dda = "Secondary Marketing Title 2";

// id: "60a8b893-8324-4d4c-a71c-1b2bbcd0d35f"
// title: ""
// type: :reference
// key: "label"
// parent_id: "7b90629e-9aae-47dd-839c-6a81db1d714c"
export function Label60a8b89383244d4cA71c1b2bbcd0d35f(props: any) {
  return (
    <DefaultLogoA182164aA29b448196f6C7cc43bdcac1 parentTag="Label60a8b89383244d4cA71c1b2bbcd0d35f" {...props} />
  );
}

// id: "36ac42e4-c693-41c3-bbff-06083b4501ae"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeader36ac42e4C69341c3Bbff06083b4501ae(props: any) {
  return (
    <ShortHeaderC55b58f9E0c24433Ab4aEfe5933ca7fc parentTag="MembersAreaHeader36ac42e4C69341c3Bbff06083b4501ae" header-top={<DefaultHeaderTopA518c179Cd8e45128d3d92afd7fa64f9 parentTag="HeaderTopAfb2222d3f2244748715252a9b885249" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "a182164a-a29b-4481-96f6-c7cc43bdcac1"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogoA182164aA29b448196f6C7cc43bdcac1(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg" className={`${parentTag || ""} DefaultLogoA182164aA29b448196f6C7cc43bdcac1 logo`} alt="logo" />
  );
}

// id: "c55b58f9-e0c2-4433-ab4a-efe5933ca7fc"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeaderC55b58f9E0c24433Ab4aEfe5933ca7fc(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeaderC55b58f9E0c24433Ab4aEfe5933ca7fc site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo7b90629e9aae47dd839c6a81db1d714c", parentTag)} label={<DefaultLogoA182164aA29b448196f6C7cc43bdcac1 parentTag="Label60a8b89383244d4cA71c1b2bbcd0d35f" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation9345e34f386249ff9ce2F482ece8257c", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "523d0702-9d36-45c7-872d-99557df1e89d"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeader523d07029d3645c7872d99557df1e89d(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeader523d07029d3645c7872d99557df1e89d site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopA518c179Cd8e45128d3d92afd7fa64f9 parentTag="HeaderTop7791ed43C3a64da587313f12b03d558c" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero00ddc5c5C8334139B48a6e4c0f3395c5 parentTag="Hero80081c2503f04664Bba5Fe7c59a05117" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "00e5a222-4a9d-4527-b17d-62517fbbe273"
// title: ""
// type: :reference
// key: "header"
// parent_id: "cd34a0f4-94a3-445f-a2c5-687132475506"
export function Header00e5a2224a9d4527B17d62517fbbe273(props: any) {
  return (
    <HomeHeader4bcfbad5312e48a28dc2D3a99ec63a70 parentTag="Header00e5a2224a9d4527B17d62517fbbe273" {...props} />
  );
}

// id: "3ecbfa89-2885-4d87-9070-a319d5086aab"
// title: ""
// type: :reference
// key: "header"
// parent_id: "dc9d4c54-954a-443b-b3cc-c65117d4866c"
export function Header3ecbfa8928854d879070A319d5086aab(props: any) {
  return (
    <MembersAreaHeader36ac42e4C69341c3Bbff06083b4501ae parentTag="Header3ecbfa8928854d879070A319d5086aab" {...props} />
  );
}

// id: "7b90629e-9aae-47dd-839c-6a81db1d714c"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "c55b58f9-e0c2-4433-ab4a-efe5933ca7fc"
export function LinkedLogo7b90629e9aae47dd839c6a81db1d714c(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo7b90629e9aae47dd839c6a81db1d714c", parentTag)} label={<DefaultLogoA182164aA29b448196f6C7cc43bdcac1 parentTag="Label60a8b89383244d4cA71c1b2bbcd0d35f" alt="logo" />} {...props} />
  );
}

// id: "9345e34f-3862-49ff-9ce2-f482ece8257c"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "c55b58f9-e0c2-4433-ab4a-efe5933ca7fc"
export function Navigation9345e34f386249ff9ce2F482ece8257c(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation9345e34f386249ff9ce2F482ece8257c", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "ad801bc5-188f-41da-8fa4-e63a24d2c8c0"
// title: "Marketing Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "eac829dd-9e6b-4a54-8bf3-5fd8e542eec5"
export const NavMenuSlugAd801bc5188f41da8fa4E63a24d2c8c0 = "marketing-primary-nav";

// id: "53eff5ee-483d-4391-b944-193ba8636df9"
// title: ""
// type: :text
// key: "label"
// parent_id: "cc6c3520-12d8-43eb-8c6c-8f08e3011bac"
export const Label53eff5ee483d4391B944193ba8636df9 = "Join Us";

// id: "23a3e75d-bcb7-45d2-9108-1cbdea57725e"
// title: ""
// type: :text
// key: "title"
// parent_id: "720b35b7-f4f7-4e27-a941-e00b75477b93"
export const Title23a3e75dBcb745d291081cbdea57725e = "About Us";

// id: "56f84bae-6bf6-401f-a809-be522a5bdb33"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "c6b8721d-cc2a-43c3-aac8-d3a7319de1b2"
export const PostSlug56f84bae6bf6401fA809Be522a5bdb33 = "about-us-posts";

// id: "9377acb3-3f33-498e-aefe-ec01801f7a0b"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBody9377acb33f33498eAefeEc01801f7a0b(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBody9377acb33f33498eAefeEc01801f7a0b page-body`}>
      {props["marketingCards"] || <MarketingCardsE996e7ab3f2c4cb4B62cB66db50a7e91 parentTag="MarketingCards603105ccE2fe4974B7a5F73e97a0b22a" postSlug="home-page-posts2" {...props} />}
    </div>
  );
}

// id: "abfdacc4-c1ff-442a-b94d-1c2e954f3fdb"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuoteAbfdacc4C1ff442aB94d1c2e954f3fdb(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuoteAbfdacc4C1ff442aB94d1c2e954f3fdb`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "fa8715cc-22f7-44a4-8992-8e0f4979845a"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPageFa8715cc22f744a489928e0f4979845a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPageFa8715cc22f744a489928e0f4979845a`}>
      {props["header"] || <SecondaryMarketingHeader523d07029d3645c7872d99557df1e89d parentTag="HeaderE9d5b8d7Eea94ac8A16aA3aa34f549b2" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooter58f090de562840278f50E0c9eca3f31c parentTag="FooterF720cb35F7954cebAc94Af9f9bcb0287" {...props} />}
    </div>
  );
}

// id: "cd34a0f4-94a3-445f-a2c5-687132475506"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePageCd34a0f494a3445fA2c5687132475506(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageCd34a0f494a3445fA2c5687132475506 page`}>
      {props["header"] || <HomeHeader4bcfbad5312e48a28dc2D3a99ec63a70 parentTag="Header00e5a2224a9d4527B17d62517fbbe273" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack51b7b83cA32f4cbdAd703cd562c8010a", parentTag)} {...props} />}
      {props["body"] || <MarketingBody9377acb33f33498eAefeEc01801f7a0b parentTag="Body009d605dC74b4f4c91e0Cb1ddf464849" {...props} />}
      {props["quote"] || <PageQuote parentTag={buildClassName("Quote2b47759d8d694da39b06757185ed3ef3", parentTag)} contentSlug="home-page-quote" {...props} />}
      {props["footer"] || <DefaultFooter58f090de562840278f50E0c9eca3f31c parentTag="FooterB13c629eC86e4026B6c4B7f13ee3906f" {...props} />}
    </div>
  );
}

// id: "51b7b83c-a32f-4cbd-ad70-3cd562c8010a"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "cd34a0f4-94a3-445f-a2c5-687132475506"
export function WelcomeBack51b7b83cA32f4cbdAd703cd562c8010a(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack51b7b83cA32f4cbdAd703cd562c8010a", parentTag)} {...props} />
  );
}

// id: "7a712765-d105-4d11-8faa-c7725d66ed6a"
// title: ""
// type: :reference
// key: "header"
// parent_id: "30c49ee9-7543-43ef-8709-d0f8d8fff46f"
export function Header7a712765D1054d118faaC7725d66ed6a(props: any) {
  return (
    <SecondaryMarketingHeader523d07029d3645c7872d99557df1e89d parentTag="Header7a712765D1054d118faaC7725d66ed6a" title="Join Us" {...props} />
  );
}

// id: "98a01dbd-6664-4788-b18b-c8a35a4cf58f"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "a518c179-cd8e-4512-8d3d-92afd7fa64f9"
export function Navigation98a01dbd66644788B18bC8a35a4cf58f(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation98a01dbd66644788B18bC8a35a4cf58f", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />
  );
}

// id: "a122fd06-4fee-4aac-841a-30d65580edce"
// title: ""
// type: :html
// key: "logo"
// parent_id: "58f090de-5628-4027-8f50-e0c9eca3f31c"
export function LogoA122fd064fee4aac841a30d65580edce(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide-white-with-font.svg" alt="Demo 1 logo" className={`${parentTag || ""} LogoA122fd064fee4aac841a30d65580edce logo`} />
  );
}

// id: "c6b8721d-cc2a-43c3-aac8-d3a7319de1b2"
// title: ""
// type: :reference
// key: "body"
// parent_id: "b78a838a-ede6-4259-ade3-185e82fc2f71"
export function BodyC6b8721dCc2a43c3Aac8D3a7319de1b2(props: any) {
  return (
    <MarketingBody9377acb33f33498eAefeEc01801f7a0b parentTag="BodyC6b8721dCc2a43c3Aac8D3a7319de1b2" postSlug="about-us-posts" {...props} />
  );
}

// id: "4ff71ef8-14aa-4d56-ba24-604cb5d1f5d7"
// title: ""
// type: :text
// key: "url"
// parent_id: "661f9fee-f3f2-4dd6-a6e2-80196a003c48"
export const Url4ff71ef814aa4d56Ba24604cb5d1f5d7 = "/";

// id: "c65dc558-d135-49d8-b716-d85a78931274"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "a85d0570-53d5-407d-a62d-047d3254e040"
export const NavMenuSlugC65dc558D13549d8B716D85a78931274 = "members-primary-nav";

// id: "24809a36-6e72-4aa9-b8f8-4b16ea75a95e"
// title: ""
// type: :reference
// key: "label"
// parent_id: "661f9fee-f3f2-4dd6-a6e2-80196a003c48"
export function Label24809a366e724aa9B8f84b16ea75a95e(props: any) {
  return (
    <DefaultLogoA182164aA29b448196f6C7cc43bdcac1 parentTag="Label24809a366e724aa9B8f84b16ea75a95e" {...props} />
  );
}

// id: "b78a838a-ede6-4259-ade3-185e82fc2f71"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPageB78a838aEde64259Ade3185e82fc2f71(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPageB78a838aEde64259Ade3185e82fc2f71 page`}>
      {props["header"] || <SecondaryMarketingHeader523d07029d3645c7872d99557df1e89d parentTag="Header720b35b7F4f74e27A941E00b75477b93" title="About Us" {...props} />}
      {props["body"] || <MarketingBody9377acb33f33498eAefeEc01801f7a0b parentTag="BodyC6b8721dCc2a43c3Aac8D3a7319de1b2" postSlug="about-us-posts" {...props} />}
      {props["footer"] || <DefaultFooter58f090de562840278f50E0c9eca3f31c parentTag="FooterA0329677E84f4a658b9c2d35744f70c2" {...props} />}
    </div>
  );
}

// id: "b8751341-81ac-4417-a11e-a784bc8b523a"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPageB875134181ac4417A11eA784bc8b523a(props: any) {
  return (
    <SecondaryPage7e0dc75fF32b45d88bd3D408985fdfda parentTag="JoinUsPageB875134181ac4417A11eA784bc8b523a" header={<SecondaryMarketingHeader523d07029d3645c7872d99557df1e89d parentTag="HeaderE56b475aB5f24f9b8c28F7be8006657f" title="Join Us" />} {...props} />
  );
}

// id: "4bcfbad5-312e-48a2-8dc2-d3a99ec63a70"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeader4bcfbad5312e48a28dc2D3a99ec63a70(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeader4bcfbad5312e48a28dc2D3a99ec63a70 site-header`}>
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopA518c179Cd8e45128d3d92afd7fa64f9 parentTag="HeaderTopC475cc9013ec41c7A12d1eccaa13ee85" {...props} />}
      </div>
      {props["hero"] || <HomePageHero3a55e1a689b64ba6Aa8a8162bc14c090 parentTag="Hero830430d646944f3786f20bd0071f671a" {...props} />}
    </div>
  );
}

// id: "e996e7ab-3f2c-4cb4-b62c-b66db50a7e91"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCardsE996e7ab3f2c4cb4B62cB66db50a7e91(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCardsE996e7ab3f2c4cb4B62cB66db50a7e91", parentTag)} buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" postSlug="home-page-posts2" {...props} />
  );
}

// id: "a0329677-e84f-4a65-8b9c-2d35744f70c2"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "b78a838a-ede6-4259-ade3-185e82fc2f71"
export function FooterA0329677E84f4a658b9c2d35744f70c2(props: any) {
  return (
    <DefaultFooter58f090de562840278f50E0c9eca3f31c parentTag="FooterA0329677E84f4a658b9c2d35744f70c2" {...props} />
  );
}

// id: "661f9fee-f3f2-4dd6-a6e2-80196a003c48"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "a518c179-cd8e-4512-8d3d-92afd7fa64f9"
export function LinkedLogo661f9feeF3f24dd6A6e280196a003c48(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo661f9feeF3f24dd6A6e280196a003c48", parentTag)} className="linked-logo" url="/" label={<DefaultLogoA182164aA29b448196f6C7cc43bdcac1 parentTag="Label24809a366e724aa9B8f84b16ea75a95e" alt="logo" />} {...props} />
  );
}

// id: "4c306140-a8a6-438a-ad68-a651ac11797e"
// title: ""
// type: :text
// key: "title"
// parent_id: "eac829dd-9e6b-4a54-8bf3-5fd8e542eec5"
export const Title4c306140A8a6438aAd68A651ac11797e = null;

// id: "2ef90ab9-8630-4101-92fb-1734f8f2ba95"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "4bcfbad5-312e-48a2-8dc2-d3a99ec63a70"
export const BackgroundImage2ef90ab98630410192fb1734f8f2ba95 = "https://mp1md-pub.s3.amazonaws.com/spp/images/page-background-photo.jpg";

// id: "afb2222d-3f22-4474-8715-252a9b885249"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "36ac42e4-c693-41c3-bbff-06083b4501ae"
export function HeaderTopAfb2222d3f2244748715252a9b885249(props: any) {
  return (
    <DefaultHeaderTopA518c179Cd8e45128d3d92afd7fa64f9 parentTag="HeaderTopAfb2222d3f2244748715252a9b885249" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "2b47759d-8d69-4da3-9b06-757185ed3ef3"
// title: ""
// type: :gf_preset
// key: "quote"
// parent_id: "cd34a0f4-94a3-445f-a2c5-687132475506"
export function Quote2b47759d8d694da39b06757185ed3ef3(props: any) {
  const PageQuote = AppStore.presets["PageQuote"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PageQuote parentTag={buildClassName("Quote2b47759d8d694da39b06757185ed3ef3", parentTag)} contentSlug="home-page-quote" {...props} />
  );
}

// id: "38009ad3-5dea-4fcf-a971-e7606242f1cf"
// title: ""
// type: :text
// key: "class"
// parent_id: "98a01dbd-6664-4788-b18b-c8a35a4cf58f"
export const Class38009ad35dea4fcfA971E7606242f1cf = "navigation";

// id: "af9857d4-c4f6-4fb1-bc74-54e5c40220e8"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "98a01dbd-6664-4788-b18b-c8a35a4cf58f"
export const NavMenuSlugAf9857d4C4f64fb1Bc7454e5c40220e8 = "marketing-primary-nav";

// id: "9f48eec8-7f93-4ead-ac08-9cd90bd4c7eb"
// title: ""
// type: :text
// key: "title"
// parent_id: "7a712765-d105-4d11-8faa-c7725d66ed6a"
export const Title9f48eec87f934eadAc089cd90bd4c7eb = "Join Us";

// id: "4c395058-ca9f-4355-b4db-ca5b8872ec4d"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefault4c395058Ca9f4355B4dbCa5b8872ec4d(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefault4c395058Ca9f4355B4dbCa5b8872ec4d page`}>
      {props["header"] || <MembersAreaHeader36ac42e4C69341c3Bbff06083b4501ae parentTag="HeaderA85d057053d5407dA62d047d3254e040" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooter58f090de562840278f50E0c9eca3f31c parentTag="FooterD3d9d0976aaf4d74965eFcc5ef4a9630" {...props} />}
    </div>
  );
}

// id: "8f801bd7-18b4-4519-b59f-4586998f97e3"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const Href8f801bd718b44519B59f4586998f97e3 = "/";

// id: "58f090de-5628-4027-8f50-e0c9eca3f31c"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooter58f090de562840278f50E0c9eca3f31c(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooter58f090de562840278f50E0c9eca3f31c footer`}>
      {props["links"] || <Navigation parentTag={buildClassName("Links02e840e916474847B28f835a11bf06fa", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <LogoA122fd064fee4aac841a30d65580edce />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("Copyright65e244dbBc0345d3Bca66370e6b7b6a8", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("Groupflow46ac364dBde349cb8453F716d0eec34e", parentTag)} {...props} />}
    </div>
  );
}

// id: "02e840e9-1647-4847-b28f-835a11bf06fa"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "58f090de-5628-4027-8f50-e0c9eca3f31c"
export function Links02e840e916474847B28f835a11bf06fa(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("Links02e840e916474847B28f835a11bf06fa", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "d3d9d097-6aaf-4d74-965e-fcc5ef4a9630"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "4c395058-ca9f-4355-b4db-ca5b8872ec4d"
export function FooterD3d9d0976aaf4d74965eFcc5ef4a9630(props: any) {
  return (
    <DefaultFooter58f090de562840278f50E0c9eca3f31c parentTag="FooterD3d9d0976aaf4d74965eFcc5ef4a9630" {...props} />
  );
}

// id: "009d605d-c74b-4f4c-91e0-cb1ddf464849"
// title: ""
// type: :reference
// key: "body"
// parent_id: "cd34a0f4-94a3-445f-a2c5-687132475506"
export function Body009d605dC74b4f4c91e0Cb1ddf464849(props: any) {
  return (
    <MarketingBody9377acb33f33498eAefeEc01801f7a0b parentTag="Body009d605dC74b4f4c91e0Cb1ddf464849" {...props} />
  );
}

// id: "a63354d3-aef1-4fbc-a101-0f63f5c370f6"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "c55b58f9-e0c2-4433-ab4a-efe5933ca7fc"
export const BackgroundImageA63354d3Aef14fbcA1010f63f5c370f6 = "https://mp1md-pub.s3.amazonaws.com/orgs/sample/city-background.jpg";

// id: "720b35b7-f4f7-4e27-a941-e00b75477b93"
// title: ""
// type: :reference
// key: "header"
// parent_id: "b78a838a-ede6-4259-ade3-185e82fc2f71"
export function Header720b35b7F4f74e27A941E00b75477b93(props: any) {
  return (
    <SecondaryMarketingHeader523d07029d3645c7872d99557df1e89d parentTag="Header720b35b7F4f74e27A941E00b75477b93" title="About Us" {...props} />
  );
}

// id: "e56b475a-b5f2-4f9b-8c28-f7be8006657f"
// title: ""
// type: :reference
// key: "header"
// parent_id: "b8751341-81ac-4417-a11e-a784bc8b523a"
export function HeaderE56b475aB5f24f9b8c28F7be8006657f(props: any) {
  return (
    <SecondaryMarketingHeader523d07029d3645c7872d99557df1e89d parentTag="HeaderE56b475aB5f24f9b8c28F7be8006657f" title="Join Us" {...props} />
  );
}

// id: "dc02bebe-a4b9-433f-8a7c-2b9648e34a9c"
// title: ""
// type: :text
// key: "imageUrl"
// parent_id: "a182164a-a29b-4481-96f6-c7cc43bdcac1"
export const ImageUrlDc02bebeA4b9433f8a7c2b9648e34a9c = "https://mp1md-pub.s3.amazonaws.com/orgs/demo-1/logo-wide.svg";

// id: "309274bc-fb4b-496d-a388-0b95137c012f"
// title: ""
// type: :text
// key: "contentSlug"
// parent_id: "2b47759d-8d69-4da3-9b06-757185ed3ef3"
export const ContentSlug309274bcFb4b496dA3880b95137c012f = "home-page-quote";

// id: "070b6152-09c6-4662-89d6-a4f6b36378aa"
// title: ""
// type: :text
// key: "postSlug"
// parent_id: "603105cc-e2fe-4974-b7a5-f73e97a0b22a"
export const PostSlug070b615209c6466289d6A4f6b36378aa = "home-page-posts2";

// id: "fa7b4e3a-f581-41dc-b86b-33a83fc872d8"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "9345e34f-3862-49ff-9ce2-f482ece8257c"
export const NavMenuSlugFa7b4e3aF58141dcB86b33a83fc872d8 = "members-primary-nav";

// id: "7e0dc75f-f32b-45d8-8bd3-d408985fdfda"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage7e0dc75fF32b45d88bd3D408985fdfda(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage7e0dc75fF32b45d88bd3D408985fdfda`}>
      {props["header"] || <SecondaryMarketingHeader523d07029d3645c7872d99557df1e89d parentTag="Header4f46388fF5ae45b6A6d6Dbc626ee52aa" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooter58f090de562840278f50E0c9eca3f31c parentTag="FooterB4e07b5410ce499bAe4c653b383080e2" {...props} />}
    </div>
  );
}

// id: "787f5671-2616-48ff-9879-443c9facd0f1"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function Footer787f5671261648ff9879443c9facd0f1(props: any) {
  return (
    <DefaultFooter58f090de562840278f50E0c9eca3f31c parentTag="Footer787f5671261648ff9879443c9facd0f1" {...props} />
  );
}

// id: "a518c179-cd8e-4512-8d3d-92afd7fa64f9"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTopA518c179Cd8e45128d3d92afd7fa64f9(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTopA518c179Cd8e45128d3d92afd7fa64f9 header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo661f9feeF3f24dd6A6e280196a003c48", parentTag)} className="linked-logo" url="/" label={<DefaultLogoA182164aA29b448196f6C7cc43bdcac1 parentTag="Label24809a366e724aa9B8f84b16ea75a95e" alt="logo" />} {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation98a01dbd66644788B18bC8a35a4cf58f", parentTag)} className="navigation" navMenuSlug="marketing-primary-nav" {...props} />}
    </div>
  );
}

// id: "80081c25-03f0-4664-bba5-fe7c59a05117"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "523d0702-9d36-45c7-872d-99557df1e89d"
export function Hero80081c2503f04664Bba5Fe7c59a05117(props: any) {
  return (
    <SecondaryMarketingHero00ddc5c5C8334139B48a6e4c0f3395c5 parentTag="Hero80081c2503f04664Bba5Fe7c59a05117" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "b13c629e-c86e-4026-b6c4-b7f13ee3906f"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "cd34a0f4-94a3-445f-a2c5-687132475506"
export function FooterB13c629eC86e4026B6c4B7f13ee3906f(props: any) {
  return (
    <DefaultFooter58f090de562840278f50E0c9eca3f31c parentTag="FooterB13c629eC86e4026B6c4B7f13ee3906f" {...props} />
  );
}

// id: "b2de0b61-acd5-4c75-bcaf-d99697967826"
// title: ""
// type: :reference
// key: "header"
// parent_id: "9448ddf9-e7dd-4b52-ab5a-639dc6345190"
export function HeaderB2de0b61Acd54c75BcafD99697967826(props: any) {
  return (
    <SecondaryMarketingHeader523d07029d3645c7872d99557df1e89d parentTag="HeaderB2de0b61Acd54c75BcafD99697967826" title="Leveling" {...props} />
  );
}

// id: "603105cc-e2fe-4974-b7a5-f73e97a0b22a"
// title: ""
// type: :reference
// key: "marketingCards"
// parent_id: "9377acb3-3f33-498e-aefe-ec01801f7a0b"
export function MarketingCards603105ccE2fe4974B7a5F73e97a0b22a(props: any) {
  return (
    <MarketingCardsE996e7ab3f2c4cb4B62cB66db50a7e91 parentTag="MarketingCards603105ccE2fe4974B7a5F73e97a0b22a" postSlug="home-page-posts2" {...props} />
  );
}

// id: "7791ed43-c3a6-4da5-8731-3f12b03d558c"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "523d0702-9d36-45c7-872d-99557df1e89d"
export function HeaderTop7791ed43C3a64da587313f12b03d558c(props: any) {
  return (
    <DefaultHeaderTopA518c179Cd8e45128d3d92afd7fa64f9 parentTag="HeaderTop7791ed43C3a64da587313f12b03d558c" {...props} />
  );
}

// id: "c475cc90-13ec-41c7-a12d-1eccaa13ee85"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "4bcfbad5-312e-48a2-8dc2-d3a99ec63a70"
export function HeaderTopC475cc9013ec41c7A12d1eccaa13ee85(props: any) {
  return (
    <DefaultHeaderTopA518c179Cd8e45128d3d92afd7fa64f9 parentTag="HeaderTopC475cc9013ec41c7A12d1eccaa13ee85" {...props} />
  );
}

// id: "b473b729-18e1-4ddf-8019-4af9be9f0d8e"
// title: ""
// type: :text
// key: "title"
// parent_id: "523d0702-9d36-45c7-872d-99557df1e89d"
export const TitleB473b72918e14ddf80194af9be9f0d8e = "Secondary Marketing Title 1";

// id: "ee41d206-9a08-4daa-843d-3e739fc740fe"
// title: ""
// type: :text
// key: "title"
// parent_id: "4f46388f-f5ae-45b6-a6d6-dbc626ee52aa"
export const TitleEe41d2069a084daa843d3e739fc740fe = "Secondary Page";

// id: "8bd8c343-9544-4bc7-8caf-48a9037a2b25"
// title: ""
// type: :text
// key: "title"
// parent_id: "e56b475a-b5f2-4f9b-8c28-f7be8006657f"
export const Title8bd8c34395444bc78caf48a9037a2b25 = "Join Us";

const Components = {
  TitleD9b90aa32034415d95c799c10eb4a675,
  ResourcesPageEe4388b908e3489996b34725fe3f71a5,
  SignupPages30c49ee9754343ef8709D0f8d8fff46f,
  AboutLevelingPage9448ddf9E7dd4b52Ab5a639dc6345190,
  Footer23629f140e364851Bae346180a44cf41,
  Header4f46388fF5ae45b6A6d6Dbc626ee52aa,
  ButtonClassCbc977a8190041efBeb3955f39e56908,
  ButtonCc6c352012d843eb8c6c8f08e3011bac,
  FooterF720cb35F7954cebAc94Af9f9bcb0287,
  Alt8e9bb26612fc4e59B775858541d1d87c,
  NavMenuSlug4591d73cF9e347a78fe1147f670c7d1c,
  NavMenuSlug77d485bd92a34303B7a1D87e9de87d94,
  Title5403b5f7E27d4bf2B97b529d98d54adc,
  AdminPages076a9452Be37425dAfc52f4755b5f4a7,
  Label0bd3bb0182e54eef9e28284b7cbb643b,
  SecondaryMarketingHero00ddc5c5C8334139B48a6e4c0f3395c5,
  MessagePagesDc9d4c54954a443bB3ccC65117d4866c,
  HeaderA85d057053d5407dA62d047d3254e040,
  PostSlug9ecd1fe7562b4fdbB6ad8fb4a222d4da,
  SecondaryNav73eba9deA2f147e594554161daa4e3c0,
  HeaderE9d5b8d7Eea94ac8A16aA3aa34f549b2,
  TitleE89283697b0a4183B40b5fb216437032,
  Footer7c3354f2A5fa4cc786a1807f3424cbab,
  Href7a77ec28530b427cA6145d49d5e99e90,
  ClassCd7adecdEb28471cA42c0141c002e833,
  TitleDaac287e871049119d0f3eae43b0e6b2,
  DefaultHeaderEac829dd9e6b4a548bf35fd8e542eec5,
  MemberPages15378abaC30e4e908489Af103ed1f8e3,
  Header8a74f990210b4adaBd21B2f3958cd134,
  HomePageHero3a55e1a689b64ba6Aa8a8162bc14c090,
  Header631e1384C4f441b6A1cdD9a4a6f40d35,
  Hero830430d646944f3786f20bd0071f671a,
  Copyright65e244dbBc0345d3Bca66370e6b7b6a8,
  FooterB4e07b5410ce499bAe4c653b383080e2,
  Header7340cd2e85f24c7f8081723f06420f48,
  Groupflow46ac364dBde349cb8453F716d0eec34e,
  Title71d47674C64f41beA6c0Ba16a11b7a12,
  Title90fc758bF07b43a0B3918ec730979dda,
  Label60a8b89383244d4cA71c1b2bbcd0d35f,
  MembersAreaHeader36ac42e4C69341c3Bbff06083b4501ae,
  DefaultLogoA182164aA29b448196f6C7cc43bdcac1,
  ShortHeaderC55b58f9E0c24433Ab4aEfe5933ca7fc,
  SecondaryMarketingHeader523d07029d3645c7872d99557df1e89d,
  Header00e5a2224a9d4527B17d62517fbbe273,
  Header3ecbfa8928854d879070A319d5086aab,
  LinkedLogo7b90629e9aae47dd839c6a81db1d714c,
  Navigation9345e34f386249ff9ce2F482ece8257c,
  NavMenuSlugAd801bc5188f41da8fa4E63a24d2c8c0,
  Label53eff5ee483d4391B944193ba8636df9,
  Title23a3e75dBcb745d291081cbdea57725e,
  PostSlug56f84bae6bf6401fA809Be522a5bdb33,
  MarketingBody9377acb33f33498eAefeEc01801f7a0b,
  PageQuoteAbfdacc4C1ff442aB94d1c2e954f3fdb,
  PublicEventsPageFa8715cc22f744a489928e0f4979845a,
  HomePageCd34a0f494a3445fA2c5687132475506,
  WelcomeBack51b7b83cA32f4cbdAd703cd562c8010a,
  Header7a712765D1054d118faaC7725d66ed6a,
  Navigation98a01dbd66644788B18bC8a35a4cf58f,
  LogoA122fd064fee4aac841a30d65580edce,
  BodyC6b8721dCc2a43c3Aac8D3a7319de1b2,
  Url4ff71ef814aa4d56Ba24604cb5d1f5d7,
  NavMenuSlugC65dc558D13549d8B716D85a78931274,
  Label24809a366e724aa9B8f84b16ea75a95e,
  AboutUsPageB78a838aEde64259Ade3185e82fc2f71,
  JoinUsPageB875134181ac4417A11eA784bc8b523a,
  HomeHeader4bcfbad5312e48a28dc2D3a99ec63a70,
  MarketingCardsE996e7ab3f2c4cb4B62cB66db50a7e91,
  FooterA0329677E84f4a658b9c2d35744f70c2,
  LinkedLogo661f9feeF3f24dd6A6e280196a003c48,
  Title4c306140A8a6438aAd68A651ac11797e,
  BackgroundImage2ef90ab98630410192fb1734f8f2ba95,
  HeaderTopAfb2222d3f2244748715252a9b885249,
  Quote2b47759d8d694da39b06757185ed3ef3,
  Class38009ad35dea4fcfA971E7606242f1cf,
  NavMenuSlugAf9857d4C4f64fb1Bc7454e5c40220e8,
  Title9f48eec87f934eadAc089cd90bd4c7eb,
  MembersAreaDefault4c395058Ca9f4355B4dbCa5b8872ec4d,
  Href8f801bd718b44519B59f4586998f97e3,
  DefaultFooter58f090de562840278f50E0c9eca3f31c,
  Links02e840e916474847B28f835a11bf06fa,
  FooterD3d9d0976aaf4d74965eFcc5ef4a9630,
  Body009d605dC74b4f4c91e0Cb1ddf464849,
  BackgroundImageA63354d3Aef14fbcA1010f63f5c370f6,
  Header720b35b7F4f74e27A941E00b75477b93,
  HeaderE56b475aB5f24f9b8c28F7be8006657f,
  ImageUrlDc02bebeA4b9433f8a7c2b9648e34a9c,
  ContentSlug309274bcFb4b496dA3880b95137c012f,
  PostSlug070b615209c6466289d6A4f6b36378aa,
  NavMenuSlugFa7b4e3aF58141dcB86b33a83fc872d8,
  SecondaryPage7e0dc75fF32b45d88bd3D408985fdfda,
  Footer787f5671261648ff9879443c9facd0f1,
  DefaultHeaderTopA518c179Cd8e45128d3d92afd7fa64f9,
  Hero80081c2503f04664Bba5Fe7c59a05117,
  FooterB13c629eC86e4026B6c4B7f13ee3906f,
  HeaderB2de0b61Acd54c75BcafD99697967826,
  MarketingCards603105ccE2fe4974B7a5F73e97a0b22a,
  HeaderTop7791ed43C3a64da587313f12b03d558c,
  HeaderTopC475cc9013ec41c7A12d1eccaa13ee85,
  TitleB473b72918e14ddf80194af9be9f0d8e,
  TitleEe41d2069a084daa843d3e739fc740fe,
  Title8bd8c34395444bc78caf48a9037a2b25
}

export default Components;